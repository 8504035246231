<template>
  <section ref="contact" class="contact container">
    <p class="title has-text-link is-size-4">Contact</p>
    <p class="text">
      You see possibilities to build something together? <br />
      Reach out to me!
    </p>
    <p class="text"></p>

    <ul class="has-text-link">
      <li>
        <a href="tel:+3-168-364-4226" class="has-text-link">
          <b-icon icon="phone" /> +31 6 83 64 42 26
        </a>
      </li>
      <li>
        <a href="mailto:info@merijnslegers.nl" class="has-text-link">
          <b-icon icon="envelope" /> info@merijnslegers.nl</a
        >
      </li>
    </ul>
    <br />
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // padding: auto 80px;
  // margin: auto 10px;

  .text {
    padding-bottom: 20px;
  }
}
</style>