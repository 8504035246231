<template>
  <section ref="portfolio" class="section">
    <div class="container has-text-centered">
      <p class="section-title">RECENT WORK</p>
      <div v-for="(project, index) in portfolio" :key="index">
        <div class="portfolio-wrapper">
          <div class="portfolio-wrapper--description">
            <div class="title has-text-link is-size-4">{{ project.title }}</div>
            <div class="subtitle is-size-6">
              {{ project.subtitle }}
            </div>
            <div class="tags">
              <span
                v-for="(tag, tagIndex) in project.tags"
                :key="tagIndex"
                class="tag is-rounded"
              >
                {{ tag }}
              </span>
            </div>
          </div>
          <div class="portfolio-wrapper--visuals" :style="project.background">
            <Carousel :project="project.images" :amount="project.amount" />
          </div>
        </div>
        <hr v-show="index !== portfolio.length - 1" />
      </div>
    </div>
  </section>
</template>

<script>
import Carousel from "./Carousel.vue";
export default {
  components: {
    Carousel,
  },
  data: () => {
    return {
      portfolio: [
        {
          title: "Analysis tool",
          subtitle: "This dashboard gives insights in...",
          tags: ["Nuxt.js", "SCSS", "ApexCharts"],
          images: "tapp",
          amount: 4,
          background:
            "background: -webkit-linear-gradient(45deg, black, #a06e43)",
        },
        {
          title: "Smart auction platform",
          subtitle: "This dashboard gives insights in...",
          tags: ["Vue.js", "SCSS", "Chart.js"],
          images: "involute",
          amount: 4,
          background:
            "background: -webkit-linear-gradient(45deg, black, #41b883)",
        },
        {
          title: "Artist website",
          subtitle: "This personalized website is...",
          tags: ["Vue.js", "SCSS"],
          images: "peterslegers",
          amount: 4,
          background:
            "background: -webkit-linear-gradient(45deg, black, #0c757d)",
        },
        {
          title: "Sales reports",
          subtitle: "This dashboard gives insights in...",
          tags: ["Tableau", "SQL"],
          images: "BI",
          amount: 3,
          background:
            "background: -webkit-linear-gradient(45deg, black, #631437)",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.portfolio-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 40px auto;
  gap: 50px;
  justify-content: space-around;

  &--description {
    text-align: left;
    width: 100%;
  }
  &--visuals {
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .portfolio-wrapper {
    flex-wrap: wrap;
  }
}

.tag {
  background-color: darkcyan;
  color: white;
}
hr {
  text-align: center;
  background: rgba(207, 198, 170, 1);
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(207, 198, 170, 1),
    rgba(0, 0, 0, 0)
  );
  // margin: 5% 35%;
  height: 1px;
}
</style>