<template>
  <nav class="navbar black is-fixed-top">
    <div class="navbar-brand">
      <!-- <a class="navbar-item" href="https://bulma.io">
        <img
          src="https://bulma.io/images/bulma-logo.png"
          width="112"
          height="28"
        />
      </a> -->

      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': showNav }"
        @click="showNav = !showNav"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-end">
        <div class="navbar-item">
          <a @click="scrollIntoViewWithOffset('intro')">
            <span>Home</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="scrollIntoViewWithOffset('services')">
            <span>Services</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="scrollIntoViewWithOffset('partners-clients')">
            <span>Partners & Clients</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="scrollIntoViewWithOffset('portfolio')">
            <span>Recent work</span>
          </a>
        </div>
        <div class="navbar-item">
          <a @click="scrollIntoViewWithOffset('contact')">
            <span>Contact</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  data: () => {
    return {
      showNav: false,
    };
  },
  methods: {
    scrollIntoViewWithOffset(selector) {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#" + selector).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          80,
      });
      this.showNav = !this.showNav;
    },
  },
};
</script>