<template>
  <section ref="partners" class="partners container">
    <p>PARTNERS & CLIENTS</p>
    <div class="logo-wrapper">
      <a
        v-for="partner in partners"
        :key="partner.name"
        class="logo-wrapper--logo"
        :href="partner.link"
        target="_blank"
      >
        <img :src="require('@/assets/partners/' + partner.logo)" alt="" />
      </a>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      partners: [
        {
          name: "TSA",
          logo: "tsa.png",
          link: "https://www.tabletsalesapp.nl/",
        },

        {
          name: "Peter Slegers kunstprojecten on tour",
          logo: "peterslegers.png",
          link: "https://www.peterslegers.com/",
        },

        {
          name: "Inspirits",
          logo: "inspirits.png",
          link: "https://www.inspirits.com/",
        },
        {
          name: "Bruggeman",
          logo: "bruggeman.png",
          link: "https://bruggeman.be/en",
        },
        {
          name: "TAPP",
          logo: "tapp.png",
          link: "https://tapp.cafe/",
        },

        // {
        //   name: "UWV",
        //   logo: "uwv.png",
        //   link: "https://www.uwv.nl/",
        // },
        {
          name: "FLS",
          logo: "fls.png",
          link: "https://flscompany.com/en/",
        },
        {
          name: "Studio Insight Out",
          logo: "insight-out.png",
          link: "https://www.studioinsightout.nl/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.partners {
  text-align: center;
  padding: 0 50px;
}

.logo-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  margin: 50px auto;

  &--logo {
    width: 180px;
    margin: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 140px;
  }
}
</style>