<template>
  <b-carousel class="carousel" :indicator-inside="false" :autoplay="false">
    <b-carousel-item v-for="i in amount" :key="i" class="test">
      <img
        class="carouse-image"
        :src="require('@/assets/portfolio/' + project + '/' + i + '.png')"
        alt=""
      />
    </b-carousel-item>
  </b-carousel>
</template>

<script>
export default {
  props: {
    project: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: false,
      default: 4,
    },
  },
};
</script>

<style lang="scss" scoped>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}

.carousel {
  // width: 90%;
  margin: 5%;
}

img {
  // width: 80%;
  border-radius: 6px;
  // filter: drop-shadow(0 0 0.5rem #444045);
}
</style>